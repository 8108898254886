import {
  Component,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
  Output,
  Input,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { ShippingAddress } from '@api/model/shippingAddress';
import { UntilDestroy } from '@core/classes/untilDestroy.class';
import { cleanFormValue, hasFormValueChanged, omitEmptyFields } from '@core/utils';
import { takeUntil } from 'rxjs';
import { PostalCodeValidator } from '@core/validators/validators';
import { FormStateService } from '@core/services/form-state.service';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskDirective } from 'ngx-mask';
import { MatButtonModule } from '@angular/material/button';
import { PbbFormFieldErrorComponent } from '../pbb-form-field-error/pbb-form-field-error.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PbbNoticeComponent } from '../pbb-notice/pbb-notice.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'pbb-address-edit',
  templateUrl: './address-edit.component.html',
  styleUrls: ['./address-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    PbbNoticeComponent,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    PbbFormFieldErrorComponent,
    MatButtonModule,
    NgxMaskDirective,
    TranslateModule,
  ],
})
export class AddressEditComponent extends UntilDestroy implements OnChanges, OnDestroy {
  @Output() submit$ = new EventEmitter<ShippingAddress>();
  @Input() address?: ShippingAddress;
  @Input() isLoading = false;
  @Input() isDefaultAddress = false;
  showRuralAddress = false;
  addressForm: FormGroup = this.fb.group({
    recipient_name: ['', [Validators.required, Validators.maxLength(100)]],
    street_name: ['', [Validators.required, Validators.maxLength(100)]],
    apartment: ['', Validators.maxLength(20)],
    city: ['', [Validators.required, Validators.maxLength(50)]],
    province: ['', [Validators.required, Validators.maxLength(20)]],
    postal_code: ['', [Validators.required, PostalCodeValidator, Validators.maxLength(40)]],
    rural_postal_address: ['', Validators.maxLength(100)],
    country: [{ value: 'CA', disabled: true }, [Validators.required]],
    address_line_1: [''],
  });

  constructor(private readonly fb: FormBuilder, private formStateService: FormStateService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const controlKey of Object.keys(this.addressForm.controls)) {
      this.addressForm
        .get(controlKey)
        ?.valueChanges.pipe(takeUntil(this.destroy$))
        .subscribe((x) => {
          this.addressForm.get(controlKey)?.markAsTouched();
        });
    }

    if (changes['address'] && changes['address'].currentValue !== changes['address'].previousValue) {
      this.initForm();
    } else {
      this.checkFormChanges();
    }
  }

  initForm() {
    const address = {
      recipient_name: this.address?.recipient_name,
      street_name: this.address?.street_name,
      apartment: this.address?.apartment,
      city: this.address?.city,
      province: this.address?.province,
      postal_code: this.address?.postal_code,
      rural_postal_address: this.address?.rural_postal_address,
      country: this.address?.country || 'CA',
      address_line_1: this.address?.address_line_1,
    };
    this.addressForm?.patchValue(address);
    this.addressForm.markAllAsTouched();
    this.checkFormChanges();
    if (this.address?.rural_postal_address) {
      this.toggleRural();
    }
  }

  submit() {
    this.formStateService.hasChange = false;
    this.submit$.emit(
      omitEmptyFields({
        ...(this.address || {}),
        ...this.addressForm?.value,
        country: this.address?.country || 'CA',
        street_number: ' ',
        apartment: cleanFormValue(this.addressForm?.value['apartment']),
        recipient_name: cleanFormValue(this.addressForm?.value['recipient_name']),
        address_line_1: cleanFormValue(this.addressForm?.value['address_line_1']),
      })
    );
  }

  getControl(formControlName: string): FormControl {
    return this.addressForm.get(formControlName) as FormControl;
  }
  toggleRural() {
    this.showRuralAddress = !this.showRuralAddress;
    const streetNameControl = this.getControl('street_name');
    const ruralAddressControlControl = this.getControl('rural_postal_address');
    if (this.showRuralAddress) {
      ruralAddressControlControl.addValidators(Validators.required);
      streetNameControl.removeValidators(Validators.required);
    } else {
      ruralAddressControlControl.setValue('');
      streetNameControl.addValidators(Validators.required);
      ruralAddressControlControl.removeValidators(Validators.required);
    }
    streetNameControl.updateValueAndValidity();
    ruralAddressControlControl.updateValueAndValidity();
  }

  checkFormChanges() {
    const initialValue = this.addressForm.value;
    this.addressForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val) => {
      this.formStateService.hasChange = hasFormValueChanged(initialValue, this.addressForm);
    });
  }
  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.formStateService.hasChange = false;
  }
}
